import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import swal from 'sweetalert2';
import { apiURL, secretKey } from '../data/urls';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'app/shared/services/config.service';
import { WebSocketService } from 'app/shared/services/web-socket.service';
import * as CryptoJS from 'crypto-js';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  public config: any = {};
  constructor(public _firebaseAuth: AngularFireAuth, private configService: ConfigService, public router: Router, private http: HttpClient, private modalService: NgbModal, private webSocketService: WebSocketService,private msalService: MsalService) {
    this.config = this.configService.templateConf;
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  loginUser(uname: string, uPassword: string) {
    const encryptedValueForURL = CryptoJS.AES.encrypt(apiURL.URL, secretKey).toString();

    localStorage.setItem('BaseURL', encryptedValueForURL);
    const encryptedValueForReclassificationApiURL = CryptoJS.AES.encrypt(apiURL.RECLASSIFICATION_API_URL, secretKey).toString()
    localStorage.setItem('ReclassificationApiURL', encryptedValueForReclassificationApiURL);
    return new Promise(resolve => {
      const reqBody = { username: uname, password: uPassword };
      const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);
      this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/authenticate', reqBody)
        .subscribe((PostData) => {
            setTimeout(() => {
              //this.getMailboxDetails().then(() => {
              if (PostData.password_expired != "Y") {
                this.GetUserRoles(PostData.USER_ID)
                  .then(() => {

                    //  console.log(PostData);
                    const encryptedValueForUName = CryptoJS.AES.encrypt(uname, secretKey).toString();
                    const encryptedValueForUserPassword = CryptoJS.AES.encrypt(uPassword, secretKey).toString();
                    const encryptedValueForUserName = CryptoJS.AES.encrypt(PostData.USER_NAME, secretKey).toString();
                    const encryptedValueForUserId = CryptoJS.AES.encrypt(JSON.stringify(PostData.USER_ID), secretKey).toString();
                    const encryptedValueForLoginId = CryptoJS.AES.encrypt(PostData.login_Id, secretKey).toString();
                    const encryptedValueForPasswordExpiresIn = CryptoJS.AES.encrypt(PostData.password_Expires_In, secretKey).toString();

                    localStorage.setItem('uname', encryptedValueForUName);
                    localStorage.setItem('uPassword', encryptedValueForUserPassword);
                    localStorage.setItem('UserName', encryptedValueForUserName);
                    localStorage.setItem('UserId', encryptedValueForUserId);
                    localStorage.setItem('LoginId', encryptedValueForLoginId);
                    localStorage.setItem('PasswordExpiresIn', encryptedValueForPasswordExpiresIn);
                    if (PostData.AUTH_TYPE == "TFA") {
                      if (PostData.TFA_TOKEN != null && PostData.TFA_TOKEN != "" && PostData.TFA_INITIAL_VERIFICATION == "Y") {
                        swal.fire({
                          title: 'Enter Authenticator Code',
                          input: 'text',
                          inputPlaceholder: 'Enter Code',
                          customClass: {
                            input: 'col-md-4'
                          },
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Submit'
                        }).then((result) => {
                          if (result.value) {
                            var reqBody = {
                              UserToken: result.value.trim(),
                              UserId: CryptoJS.AES.decrypt(localStorage.getItem('UserId'), secretKey).toString(CryptoJS.enc.Utf8)
                            }
                            console.log("reqBody", reqBody);
                            this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/tfa/verify', reqBody)
                              .subscribe((response) => {

                                if (response["status"] == 200) {

                                  const encryptedValueForUserEmail = CryptoJS.AES.encrypt(PostData.email, secretKey).toString();

                                  const encryptedValueForUserToken = CryptoJS.AES.encrypt(PostData.token, secretKey).toString();

                                  const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();

                                  const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(JSON.stringify(PostData.sessionTimer), secretKey).toString();
                                  localStorage.setItem('UserEmail', encryptedValueForUserEmail);
                                  localStorage.setItem('UserToken', encryptedValueForUserToken);
                                  localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
                                  localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
                                  //console.log("PostData.sessionTimer",PostData.sessionTimer)
                                  var rolesArray = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8));
                                  console.log("rolesArray", rolesArray);
                                  var isUser = (rolesArray.indexOf('USER') > -1) ? true : false;
                                  var isReportsPage = (rolesArray.indexOf('TRAFFIX_ADMIN') > -1 || rolesArray.indexOf('TRAFFIX_REPORTS') > -1 || rolesArray.indexOf('CS_REPORTS') > -1) ? true : false;
                                  //var isAdmin = (rolesArray.indexOf('ADMIN') > -1) ? true : false;
                                  // var rolesArray1 = rolesArray.split(',');
                                  const isAdmin = rolesArray.includes('ADMIN') && (!rolesArray.includes('TRAFFIX_ADMIN') || rolesArray.includes('TRAFFIX_ADMIN'));
                                  if (isUser) {
                                    var resObj = {
                                      "RedirectUrl": "/dashboard",
                                      "Error": "null"
                                    }
                                    resolve(resObj);
                                  }
                                  else if (isAdmin) {
                                    var resObj = {
                                      "RedirectUrl": "/dashboard",
                                      "Error": "null"
                                    }
                                    resolve(resObj);
                                  }
                                  else if (isReportsPage) {
                                    //   console.log("Roles1", rolesArray)
                                    var resObj = {
                                      "RedirectUrl": "/reports/quickViewReports",
                                      "Error": "null"
                                    }
                                    resolve(resObj);
                                  }
                                  else {
                                    var resObj = {
                                      "RedirectUrl": "/dashboard",
                                      "Error": "null"
                                    }
                                    resolve(resObj);
                                  }
                                }
                                else {
                                  var resObj = {
                                    "RedirectUrl": "/pages/login",
                                    "Error": "Invalid OTP"
                                  }
                                  resolve(resObj);
                                }
                              },
                                (err) => {
                                  console.log("err", err);
                                });
                          }
                        });
                      }
                      else {
                        //Generate new TOTP token.
                        var reqBody = {
                          UserId: PostData.USER_ID
                        }
                        this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/tfa/setup', reqBody)
                          .subscribe((tfaSetupRes) => {
                            if (tfaSetupRes['status'] === 200) {
                              //console.log(tfaSetupRes);
                              var tfa = tfaSetupRes;

                              var resObj = {
                                "RedirectUrl": "/tfa/setup",
                                "Error": "TFA set-up initiated",
                                "tfaDetails": tfa
                              }
                              resolve(resObj);
                            }
                          });
                      }
                    }
                    else if (PostData.AUTH_TYPE == "MFA") {
                      //console.log("PostData.AUTH_TYPE",PostData.AUTH_TYPE);
                      this.http.get<any>(decryptedValueForBaseURL + '/api/v1/user_accounts/generateMFA/' + PostData.USER_ID + `/` + PostData.EMAIL)
                        .subscribe((PostDataUserAccounts) => {
                          swal.fire({
                            title: 'Enter Verification Code',
                            input: 'text',
                            inputPlaceholder: 'Enter Code',
                            customClass: {
                              input: 'col-md-4'
                            },
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Submit'
                          }).then((result) => {
                            if (result.value) {
                              this.http.get<any>(decryptedValueForBaseURL + '/api/v1/user_accounts/MFA/validation/' + PostData.USER_ID + '/MFA/' + result.value.trim())
                                .subscribe((response) => {
                                  if (response) {
                                    const encryptedValueForUserEmail = CryptoJS.AES.encrypt(PostData.email, secretKey).toString();
                                    const encryptedValueForUserToken = CryptoJS.AES.encrypt(PostData.token, secretKey).toString();
                                    const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();
                                    const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(JSON.stringify(PostData.sessionTimer), secretKey).toString();

                                    localStorage.setItem('UserEmail', encryptedValueForUserEmail);
                                    localStorage.setItem('UserToken', encryptedValueForUserToken);
                                    localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
                                    localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
                                    var rolesArray = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8));

                                    var isUser = (rolesArray.indexOf('USER') > -1) ? true : false;
                                    var isReportsPage = (rolesArray.indexOf('TRAFFIX_ADMIN') > -1 || rolesArray.indexOf('TRAFFIX_REPORTS') > -1 || rolesArray.indexOf('CS_REPORTS') > -1) ? true : false;
                                    const isAdmin = rolesArray.includes('ADMIN') && (!rolesArray.includes('TRAFFIX_ADMIN') || rolesArray.includes('TRAFFIX_ADMIN'));
                                    if (isUser) {
                                      var resObj = {
                                        "RedirectUrl": "/dashboard",
                                        "Error": "null"
                                      }
                                      resolve(resObj);
                                    }
                                    else if (isAdmin) {
                                      var resObj = {
                                        "RedirectUrl": "/dashboard",
                                        "Error": "null"
                                      }
                                      resolve(resObj);
                                    }
                                    else if (isReportsPage) {
                                      var resObj = {
                                        "RedirectUrl": "/reports/quickViewReports",
                                        "Error": "null"
                                      }
                                      resolve(resObj);
                                    }
                                    else {
                                      var resObj = {
                                        "RedirectUrl": "/dashboard",
                                        "Error": "null"
                                      }
                                      resolve(resObj);
                                    }
                                  }
                                  else {
                                    var resObj = {
                                      "RedirectUrl": "/pages/login",
                                      "Error": "Invalid OTP"
                                    }
                                    resolve(resObj);
                                  }
                                });
                            }
                          });
                        });
                    }
                    else {
                      const encryptedValueForUserEmail = CryptoJS.AES.encrypt(PostData.email, secretKey).toString();
                      const encryptedValueForUserToken = CryptoJS.AES.encrypt(PostData.token, secretKey).toString();
                      const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();
                      const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(JSON.stringify(PostData.sessionTimer), secretKey).toString();
                      localStorage.setItem('UserEmail', encryptedValueForUserEmail);
                      localStorage.setItem('UserToken', encryptedValueForUserToken);
                      localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
                      localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
                      var rolesArray = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8));
                      console.log("rolesArray", rolesArray)
                      var isUser = (rolesArray.indexOf('USER') > -1) ? true : false;
                      console.log("isUser", isUser)
                      var isReportsPage = (rolesArray.indexOf('TRAFFIX_ADMIN') > -1 || rolesArray.indexOf('TRAFFIX_REPORTS') > -1 || rolesArray.indexOf('CS_REPORTS') > -1) ? true : false;
                      const isAdmin = rolesArray.includes('ADMIN') && (!rolesArray.includes('TRAFFIX_ADMIN') || rolesArray.includes('TRAFFIX_ADMIN'));

                      if (isUser) {
                        var resObj = {
                          "RedirectUrl": "/dashboard",
                          "Error": "null"
                        }
                        resolve(resObj);
                      }
                      else if (isAdmin) {
                        var resObj = {
                          "RedirectUrl": "/dashboard",
                          "Error": "null"
                        }
                        resolve(resObj);
                      }
                      else if (isReportsPage) {
                        var resObj = {
                          "RedirectUrl": "/reports/quickViewReports",
                          "Error": "null"
                        }
                        resolve(resObj);
                      }
                      else {
                        var resObj = {
                          "RedirectUrl": "/dashboard",
                          "Error": "null"
                        }
                        resolve(resObj);
                      }
                    }
                  });

              } else {
                const encryptedValueForUserName = CryptoJS.AES.encrypt(PostData.USER_NAME, secretKey).toString();
                const encryptedValueForUserId = CryptoJS.AES.encrypt(PostData.USER_ID, secretKey).toString();
                const encryptedValueForUserEmail = CryptoJS.AES.encrypt(PostData.email, secretKey).toString();
                const encryptedValueForUserToken = CryptoJS.AES.encrypt(PostData.token, secretKey).toString();
                const encryptedValueForLoginId = CryptoJS.AES.encrypt(PostData.login_Id, secretKey).toString();
                const encryptedValueForPasswordExpiresIn = CryptoJS.AES.encrypt(PostData.password_Expires_In, secretKey).toString();
                localStorage.setItem('UserName', encryptedValueForUserName);
                localStorage.setItem('UserId', encryptedValueForUserId);
                localStorage.setItem('UserEmail', encryptedValueForUserEmail);
                localStorage.setItem('UserToken', encryptedValueForUserToken);
                localStorage.setItem('LoginId', encryptedValueForLoginId);
                localStorage.setItem('PasswordExpiresIn', encryptedValueForPasswordExpiresIn);
                var resObj = {
                  "RedirectUrl": "/pages/login",
                  "Error": "Password Expired"
                }
                swal.fire({
                  title: 'Password Expired.',
                  text: "Please contact admin",
                  icon: 'warning'
                });
                resolve(resObj);
              }
            //})
          }, 300);
        },
          err => {
            const encryptedValueForInvalidUser = CryptoJS.AES.encrypt('true', secretKey).toString();

            localStorage.setItem('InvalidUser', encryptedValueForInvalidUser);
            //console.log(err);
            if (err == "User not found !") {
              var resObj = {
                "RedirectUrl": "/pages/login",
                "Error": "User not found"
              }

            }
            else if (err == "Please check your password !") {
              var resObj = {
                "RedirectUrl": "/pages/login",
                "Error": "Invalid Login Cred"
              }
            }
            else if (err == "User In-Active !") {
              //console.log("else if block for User In-Active........")
              var resObj = {
                "RedirectUrl": "/pages/login",
                "Error": "User In-Active"
              }
            }
            else {
              var resObj = {
                "RedirectUrl": "/pages/login",
                "Error": "Invalid User"
              }
            }
            resolve(resObj);
          });
    })
  }

  getMailboxDetails() {
    return new Promise((resolve, reject) => {
      const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);
      console.log("decryptedValueForBaseURL", decryptedValueForBaseURL)
      this.http.get<any>(decryptedValueForBaseURL + `/api/v1/mailbox_config/getAllMailBox`).subscribe((mailbox_config) => {
        localStorage.setItem('mailbox_1', mailbox_config[0].MAILBOX_ALIAS_NAME);
        localStorage.setItem('mailbox_2', mailbox_config[1].MAILBOX_ALIAS_NAME);
        resolve(null);
      },
        (err) => {
          console.log("error in mailbox config", err)
          reject(null);
        });
    });
  }
  GetUserRoles(userId) {
    return new Promise((resolve, reject) => {
      const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);
      this.http.get<any>(decryptedValueForBaseURL + '/api/v1/auth-service/GetUserRoles/' + userId)
        .subscribe((data) => {
          this.http.get<any>(decryptedValueForBaseURL + '/api/v1/auth-service/GetUserRoleAliasName/' + userId)
            .subscribe((dataAlias) => {
              console.log('Data Alias:', dataAlias);
              console.log('Data:', typeof data);
              const encryptedValueForRoleAliasName = CryptoJS.AES.encrypt(JSON.stringify(dataAlias), secretKey).toString();
              const encryptedValueForRoles = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
              localStorage.setItem('RoleAliasName', encryptedValueForRoleAliasName);
              localStorage.setItem('Roles', encryptedValueForRoles);
              var isAdmin = this.isAdmin() ? "true" : "false";
              const encryptedValueForisAdmin = CryptoJS.AES.encrypt(isAdmin, secretKey).toString();
              localStorage.setItem('isAdmin', encryptedValueForisAdmin);
              resolve(null);
            });
        });
    });
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)

  }

  logout() {
    this.modalService.dismissAll();
    this.webSocketService.disconnect();
    swal.close();
    this.clearAllTimeouts();
    this.msalService.logoutRedirect();
    localStorage.clear();
    this.router.navigate(['/']);
  }


  sessionManagement() {
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.logout();
    }, Number(CryptoJS.AES.decrypt(localStorage.getItem('SessionTimer'), secretKey).toString(CryptoJS.enc.Utf8)) * 1000);

    setTimeout(() => {
      const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);    //<<<---    using ()=> syntax
      swal.fire({
        title: 'Session expires in 2 minutes!!',
        text: "Your session will expires in 2 minutes!!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Refresh Session!'
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('UserName');
          localStorage.removeItem('UserId');
          localStorage.removeItem('LoginId');
          localStorage.removeItem('PasswordExpiresIn');
          localStorage.removeItem('UserEmail');
          localStorage.removeItem('UserToken');
          localStorage.removeItem('IsAuthSuccessful');
          localStorage.removeItem('SessionTimer');
          this.clearAllTimeouts();

          var uname = localStorage.getItem('uname');
          var uPassword = CryptoJS.AES.decrypt(localStorage.getItem('uPassword'), secretKey).toString(CryptoJS.enc.Utf8)
          const reqBody = { username: uname, password: uPassword };
          this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/authenticate', reqBody)
            .subscribe((PostData) => {
              // const encryptedValueForUserName = CryptoJS.AES.encrypt(PostData.USER_NAME, secretKey).toString();
              // const encryptedValueForUserId = CryptoJS.AES.encrypt(PostData.USER_ID, secretKey).toString();
              // const encryptedValueForUserEmail = CryptoJS.AES.encrypt(PostData.email, secretKey).toString();
              // const encryptedValueForUserToken = CryptoJS.AES.encrypt(PostData.token, secretKey).toString();
              // const encryptedValueForLoginId = CryptoJS.AES.encrypt(PostData.login_Id, secretKey).toString();
              // const encryptedValueForPasswordExpiresIn = CryptoJS.AES.encrypt(PostData.password_Expires_In, secretKey).toString();
              // const encryptedValueForUName = CryptoJS.AES.encrypt(uname, secretKey).toString();
              // const encryptedValueForUserPassword = CryptoJS.AES.encrypt(uPassword, secretKey).toString();
              // const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();
              // const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(PostData.sessionTimer, secretKey).toString();
              // localStorage.setItem('UserName', encryptedValueForUserName);
              // localStorage.setItem('UserId', encryptedValueForUserId);
              // localStorage.setItem('UserEmail', encryptedValueForUserEmail);
              // localStorage.setItem('UserToken', encryptedValueForUserToken);
              // localStorage.setItem('LoginId', encryptedValueForLoginId);
              // localStorage.setItem('PasswordExpiresIn', encryptedValueForPasswordExpiresIn);
              // localStorage.setItem('uname', encryptedValueForUName);
              // localStorage.setItem('uPassword', encryptedValueForUserPassword);
              // localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
              // localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
              // this.GetUserRoles(PostData.USER_ID);
              this.reLoginUser(uname);
              this.sessionManagement();
            },
              err => {
                const encryptedValueForInvalidUser = CryptoJS.AES.encrypt('true', secretKey).toString();
                localStorage.setItem('InvalidUser', encryptedValueForInvalidUser);
                this.router.navigate([''], { queryParams: { error: 'invalid', message: err.error.message } });
              });
        } else {
          this.msalService.logoutRedirect();
        }
      });
    }, (Number(CryptoJS.AES.decrypt(localStorage.getItem('SessionTimer'), secretKey).toString(CryptoJS.enc.Utf8)) - 120) * 1000);

  }

  async reLoginUser(uname: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const encryptedValueForURL = CryptoJS.AES.encrypt(apiURL.URL, secretKey).toString();

        localStorage.setItem('BaseURL', encryptedValueForURL);
        const encryptedValueForReclassificationApiURL = CryptoJS.AES.encrypt(apiURL.RECLASSIFICATION_API_URL, secretKey).toString();
        localStorage.setItem('ReclassificationApiURL', encryptedValueForReclassificationApiURL);

        const reqBody = { username: uname };
        const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);

        const postData = await this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/sso-login', reqBody).toPromise();
          //await this.getMailboxDetails();
          await this.GetUserRoles(postData.USER_ID);

          const encryptedValueForUName = CryptoJS.AES.encrypt(uname, secretKey).toString();
          const encryptedValueForUserName = CryptoJS.AES.encrypt(postData.USER_NAME, secretKey).toString();
          const encryptedValueForUserId = CryptoJS.AES.encrypt(JSON.stringify(postData.USER_ID), secretKey).toString();
          const encryptedValueForUserEmail = CryptoJS.AES.encrypt(postData.EMAIL, secretKey).toString();
          const encryptedValueForUserToken = CryptoJS.AES.encrypt(postData.token, secretKey).toString();
          const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();
          const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(JSON.stringify(postData.sessionTimer), secretKey).toString();

          localStorage.setItem('UserEmail', encryptedValueForUserEmail);
          localStorage.setItem('UserToken', encryptedValueForUserToken);
          localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
          localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
          localStorage.setItem('uname', encryptedValueForUName);
          localStorage.setItem('UserName', encryptedValueForUserName);
          localStorage.setItem('UserId', encryptedValueForUserId);
          resolve(true);
      } catch (error) {
        console.error('Error during SSO login:', error);
        reject(error);
      }
    });
  }
  clearAllTimeouts() {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
  };

  isAuthenticated() {
    const user_token = localStorage.getItem('UserToken');
    if (user_token != null) {
      return true;
    } else {
      return false;
    }
  }

  isVMApproval() {
    const isVMApproval = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8)).indexOf('VM_APPROVER') > -1;
    if (isVMApproval) {
      return true;
    } else {
      return false;
    }
  }

  isAPProcessor() {
    const isAPProcessor = CryptoJS.AES.decrypt(localStorage.getItem('RoleAliasName'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('AP_INVOICE_PROCESSOR') > -1;
    if (isAPProcessor) {
      return true;
    } else {
      return false;
    }
  }

  isAPApprover() {
    const isAPApprover = CryptoJS.AES.decrypt(localStorage.getItem('RoleAliasName'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('AP_INVOICE_APPROVER') > -1;
    if (isAPApprover) {
      return true;
    } else {
      return false;
    }
  }

  isAPExporter() {
    const isAPExporter = CryptoJS.AES.decrypt(localStorage.getItem('RoleAliasName'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('AP_INVOICE_EXPORTER') > -1;
    if (isAPExporter) {
      return true;
    } else {
      return false;
    }
  }


  isAdmin() {
    const rolesArray = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8));
    const isAdmin = rolesArray.includes('ADMIN') && (!rolesArray.includes('TRAFFIX_ADMIN') || rolesArray.includes('TRAFFIX_ADMIN'));

    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  isTraffixReports() {
    const isTraffixReports = CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('TRAFFIX_REPORTS') > -1;
    if (isTraffixReports) {
      return true;
    } else {
      return false;
    }
  }
  isCSReports() {
    const isCSReports = CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('CS_REPORTS') > -1;
    if (isCSReports) {
      return true;
    } else {
      return false;
    }
  }
  isTraffixAdmin() {
    const isTraffixAdmin = CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8).includes('TRAFFIX_ADMIN');
    //  console.log("issss traffix", isTraffixAdmin)
    if (isTraffixAdmin) {
      return true;
    } else {
      return false;
    }
  }
  isProcessor() {
    const isPROCESSOR = CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('PROCESSOR') > -1;
    if (isPROCESSOR) {
      return true;
    } else {
      return false;
    }
  }
  isUser() {
    const USER = CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8).indexOf('USER') > -1;
    if (USER) {
      return true;
    } else {
      return false;
    }
  }
}
